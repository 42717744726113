<div class="main-desk">
  <div class="product-listing-header-mobile" id="listing-anchor">
    <h1>{{ categoryItem()?.displayName ?? "Styles" }}</h1>
  </div>

  @if (showFilters()) {
    <button
      (click)="showMobileFilters()"
      mat-stroked-button
      color="accent"
      class="filters-mobile"
    >
      <div class="filter-button-container">
        <span>Filter</span>
        <mat-icon>tune</mat-icon>
      </div>
    </button>
  }

  <div style="margin-top: 20px" class="mobile" *ngIf="!emptyResults()">
    <mat-paginator
      #paginator
      [pageSizeOptions]="[12, 24, 48]"
      [pageSize]="this.pageSize()"
      [length]="this.resultSize()"
      [pageIndex]="this.pageIndex()"
      aria-label="Select page of styles"
      (page)="onPaginatorChange($event)"
    ></mat-paginator>
  </div>

  <div
    *ngIf="emptyResults() && !loading()"
    class="empty-result-container-mobile"
  >
    <span>No styles found. Please change your selections.</span>
  </div>

  <div class="center-desk">
    @if (showFilters()) {
      <div class="filters-desk">
        <div class="filters-header">
          <h3>Filter</h3>
          <button
            *ngIf="filterCategoriesSelected()"
            mat-flat-button
            color="accent"
            style="
              margin-right: 30px;
              max-height: 30px;
              font-size: 0.6em !important;
            "
            (click)="clearFilters()"
          >
            Clear
          </button>
        </div>
        <app-style-filters></app-style-filters>
      </div>
    }

    <ng-template #spinner>
      <div style="position: relative; width: 100%">
        <div class="spinner-container-enabled">
          <mat-spinner></mat-spinner>
        </div>
      </div>
    </ng-template>

    <div class="plp-desk">
      <div class="product-listing-header-desktop">
        <div class="product-header">
          <h1>{{ categoryItem()?.displayName ?? "Styles" }}</h1>
          <div [style.display]="!styles() || emptyResults() ? 'none' : ''">
            <mat-paginator
              #paginator
              [pageSizeOptions]="[12, 24, 48]"
              [pageSize]="this.pageSize()"
              [length]="this.resultSize()"
              [pageIndex]="this.pageIndex()"
              aria-label="Select page of styles"
              (page)="onPaginatorChange($event)"
            ></mat-paginator>
          </div>
        </div>
      </div>
      <ng-container *ngIf="!loading() && styles(); else spinner">
        <ul>
          <div
            class="plp-col-desk"
            *ngFor="let style of styles(); let imageIndex = index"
          >
            <li>
              <div class="plp-item">
                <a
                  [routerLink]="getStyleTitleAndCode(style)"
                  queryParamsHandling="preserve"
                >
                  <!-- [alt]="getImage(style).alt" -->
                  <img
                    class="plp-image-desk"
                    [ngSrc]="
                      imageIndex < 1
                        ? getImageFromImageKit(style).src
                        : getImageFromImageKit(style).src
                    "
                    [alt]="
                      imageIndex < 1
                        ? getImageFromImageKit(style).alt
                        : getImageFromImageKit(style).alt
                    "
                    sizes="(max-width: 299px) 85vw, (max-width: 599px) 28vw, (max-width: 1400px) 20vw, 10vw"
                    [priority]="imageIndex < 3 ? true : false"
                    [style.aspect-ratio]="'2 / 3'"
                    fill
                  />

                  <br />
                  <p class="product-title">{{ style.marketingTitle }}</p>
                  <br />

                  <div class="pricing-container">
                    <hr />
                    <span
                      class="price-descript"
                      *ngIf="style.styleType === 'Coat'"
                      >Starting At:</span
                    >
                    <ng-container
                      *ngIf="hasDisplayPrice(style); else rentBlock"
                    >
                      <div>
                        <span class="price">
                          {{ displayRentText(style) }}
                        </span>
                      </div>
                    </ng-container>
                    <ng-template #rentBlock>
                      <div>
                        <span class="price" *ngIf="getRentPrice(style) > 0"
                          >Rent: {{ getRentPrice(style) | currency }}
                        </span>
                      </div>
                    </ng-template>
                    <ng-container *ngIf="hasDisplayPrice(style); else buyBlock">
                      <div>
                        <span class="price" *ngIf="isPurchaseOnly(style)">
                          AVAILABLE TO BUY
                        </span>
                      </div>
                    </ng-container>
                    <ng-template #buyBlock>
                      <div>
                        <span class="price" *ngIf="getBuyPrice(style) > 0"
                          >Buy: {{ getBuyPrice(style) | currency }}</span
                        >
                      </div>
                    </ng-template>
                  </div>
                </a>
              </div>
            </li>
          </div>
        </ul>
      </ng-container>
      <!-- <ng-template #spinner>
        <mat-spinner></mat-spinner>
      </ng-template> -->
      <div class="empty-result-container-desktop">
        <span *ngIf="emptyResults() && !loading()"
          >No styles found. Please change your selections.</span
        >
      </div>
    </div>
  </div>
  <div [style.display]="!styles() || emptyResults() ? 'none' : ''">
    <mat-paginator
      #paginator
      [pageSizeOptions]="[12, 24, 48]"
      [pageIndex]="this.pageIndex()"
      [pageSize]="this.pageSize()"
      [length]="this.resultSize()"
      aria-label="Select page of styles"
      (page)="onPaginatorChange($event)"
    ></mat-paginator>
  </div>
</div>
