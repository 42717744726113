import { isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  computed,
  effect,
  inject,
  PLATFORM_ID,
  Renderer2,
  signal,
  ViewChild,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  EnsembleService,
  NavigationService,
  ScrollService,
  SearchService,
} from '@jfw-library/ecommerce/core';
import {
  getEnsembleBuyPrice,
  getEnsembleRentPrice,
  getFilterCategoriesByEnsemble,
  getImage,
  getImageKitImage,
  getOriginalImage,
  setColorClass,
  updateFilterCategoriesFromQueryParams,
} from 'business-logic';
import {
  Categories,
  CategoryItem,
  EcomEnsemble,
  EcomEnsemblePLP,
  FilterCategory,
  FilterOption,
  Image,
  PaginatorOptions,
  SearchCriteria,
} from 'common-types';
import {
  catchError,
  debounceTime,
  filter,
  map,
  merge,
  Observable,
  of,
  shareReplay,
  Subject,
  switchMap,
  tap,
} from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { EnsemblePlpResolverData } from '../../../resolvers/ensemble/ensemble-plp.resolver';

export interface FilterParam {
  key: string;
  value: string;
}

/// Update 1, commit 1
/// Update 2, commit 3

@Component({
  selector: 'app-ensemble-listing-page',
  templateUrl: './ensemble.component.html',
  styleUrls: ['./ensemble.component.scss'],
})
export class EnsembleListingPageComponent {
  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  imagesLoaded = signal<number[]>([]);
  private isBrowser = isPlatformBrowser(inject(PLATFORM_ID));
  private isFirstLoad = true;
  private readonly categories = new Categories();
  private readonly DEFAULT_PAGE_SIZE = 24;
  loadingSearch = signal(true);
  isLoadingSearch = effect(() =>
    console.log('loadingSearch:', this.loadingSearch()),
  );
  private readonly params$ = this.route.params;
  private readonly params = toSignal(this.params$);
  private readonly queryParams$ = this.route.queryParams;
  private readonly queryParams = toSignal(this.queryParams$);
  private readonly hasQueryParams = computed(() => {
    const queryParams = this.queryParams();
    return queryParams && Object.keys(queryParams).length > 0;
  });
  categoryItem = computed(() => {
    const params = this.params();
    return params ? this.categories.byRoute(params.category) : undefined;
  });

  pageSize = signal(this.DEFAULT_PAGE_SIZE); // TODO: change these to normal signals b/c they need to be reset when paginator is reset.
  pageIndex = signal(0);

  resultSize = signal(0);

  private readonly filterCategories$ = this.params$.pipe(
    tap(() => {
      console.log('filterCategories$');
      this.loadingSearch.set(true);
    }),
    switchMap((params) => {
      const categoryItem = this.categoryItem();
      const resolverData = this.route.snapshot.data.resolverData as
        | EnsemblePlpResolverData
        | undefined;
      const preFetchedFilterCategories =
        resolverData?.filterCategoriesData?.filterCategories;
      const resolverCategoryItem =
        resolverData?.filterCategoriesData?.categoryItem;
      const useResolverData =
        resolverCategoryItem?.category === categoryItem?.category &&
        preFetchedFilterCategories;
      if (useResolverData) {
        console.log('Using filterCategories resolver data');
        return of(preFetchedFilterCategories);
      } else {
        console.log('Not using filterCategories resolver data.');
      }

      const category = this.categoryItem()?.category;
      if (category) {
        const categories = getFilterCategoriesByEnsemble();
        console.log('Fetching filter categories...');
        return this.search
          .getDistinctFiltersByCategories(
            'ecom-ensembles',
            category,
            categories,
          )
          .pipe(
            catchError((error) => {
              console.error('Error fetching filter categories', error);
              return of([]);
            }),
          );
      }
      console.warn('No category found');
      return of([]);
    }),
    map((filters) => {
      // set selected filters based on query params
      const newFilters = updateFilterCategoriesFromQueryParams(
        this.queryParams(),
        filters,
      );
      return newFilters;
    }),
    tap((filters) => {
      // pass filterCategories to filters
      this.ensembleService.filterCategories.next(filters);
    }),
    shareReplay(1),
  );

  private readonly filterCategories = toSignal(this.filterCategories$);

  private triggerEnsembleSearch = toSignal(
    this.ensembleService.triggerEnsembleSearch.asObservable(),
  );

  filterCategoriesSelected = computed(() => {
    const filterCategories = this.filterCategories();
    const triggered = this.triggerEnsembleSearch(); // changes to the filterCategories object (by the filters) are mutations, so will not trigger this computed.  Using this to trigger the computed.
    // console.log('updating filterCategoriesSelected');
    if (filterCategories === undefined) {
      return false;
    }
    return filterCategories.some((category) =>
      category.options?.some((option) => option.selected),
    );
  });

  /** Triggered by a change in params, which triggers filterCategories$
   * (also during initial component load) */
  private readonly searchFromParams$ = this.filterCategories$.pipe(
    tap(() => {
      console.log('searchFromParams$');
      this.loadingSearch.set(true);
    }),
    switchMap((filterCategories) => {
      const categoryItem = this.categoryItem();
      if (this.isFirstLoad) {
        this.isFirstLoad = false;
        // First time rendering page (so either fetch styles or use transfer state)
        console.log(
          'First time rendering page.  Checking for ensembles resolver data',
        );

        const preFetchedResults = this.route.snapshot.data.resolverData
          .searchResults as
          | EnsemblePlpResolverData['searchResults']
          | undefined;
        if (preFetchedResults) {
          console.log('Using ensembles resolver data');
          return of({ results: preFetchedResults, resetPaginator: true });
        } else {
          console.log('No resolver data.');
        }
      } else {
        console.log('This is not the first time rendering the page.');
      }

      if (!categoryItem) {
        console.error('No category item found');
        return of({
          results: { ensembles: [], totalHits: 0 },
          resetPaginator: true,
        });
      }

      console.log('Fetching ensembles...');

      const ensembleGroup = this.params()?.ensembleGroup;
      if (ensembleGroup === 'rental') {
        console.log('  > rental');
        return this.searchEnsembles(filterCategories, this.categoryItem()); // not currently implemented, just return default search
      }
      if (ensembleGroup === 'purchase') {
        console.log('  > purchase');
        return this.searchEnsembles(filterCategories, this.categoryItem()); // not currently implemented, just return default search
      }
      console.log('  > default');
      return this.searchEnsembles(filterCategories, this.categoryItem());
    }),
  );

  /** Triggered by a change in filter selection */
  private readonly searchFromFilters$ =
    this.ensembleService.triggerEnsembleSearch.asObservable().pipe(
      filter((trigger) => trigger.triggerSearch),
      debounceTime(700), // allow for multiple selections to be made before searching
      tap((trigger) => {
        console.log('searchFromFilters$');
        this.loadingSearch.set(true);
        if (
          trigger.resetFilterParams !== undefined &&
          trigger.resetFilterParams
        ) {
          this.filterCategories()?.forEach((category) => {
            category.options?.forEach((option) => {
              option.selected = false;
            });
          });
        }
      }),
      switchMap((trigger) =>
        this.searchEnsembles(this.filterCategories(), this.categoryItem()),
      ),
      tap(() => {
        const filterCategories = this.filterCategories();

        if (filterCategories) {
          const filterParams =
            this.getParamsFromFilterCategories(filterCategories);
          this.updateBrowserUrlWithParams(filterParams, ['event']);
        } else {
          this.updateBrowserUrlWithParams({});
        }
      }),
    );

  private readonly paginatorChangeSubject = new Subject<PageEvent>();
  private readonly paginatorChange$ = this.paginatorChangeSubject
    .asObservable()
    .pipe(shareReplay(1));
  /** Triggered by a change in paginator */
  private readonly searchFromPaginator$ = this.paginatorChange$.pipe(
    tap(() => {
      console.log('searchFromPaginator$');
      this.loadingSearch.set(true);
    }),
    switchMap((event) => {
      const paginatorOptions: PaginatorOptions = {
        previousPageIndex: event.previousPageIndex ?? undefined,
        pageIndex: event.pageIndex,
        pageSize: event.pageSize,
        length: event.length,
      };
      this.pageSize.set(paginatorOptions.pageSize);
      this.pageIndex.set(paginatorOptions.pageIndex);
      return this.searchEnsembles(
        this.filterCategories(),
        this.categoryItem(),
        paginatorOptions,
      );
    }),
  );

  /** These are the Elastic responses for each of the events that can trigger a search */
  private readonly elasticSearchResults$ = merge(
    this.searchFromParams$, // only triggered by params, not queryParams
    this.searchFromFilters$, // triggered by filter selection
    this.searchFromPaginator$, // triggered by paginator change
  ).pipe(
    shareReplay(1), // this is to prevent multiple subscriptions to the same observable
  );

  private readonly ensembles$ = this.elasticSearchResults$.pipe(
    tap(({ results, resetPaginator }) => {
      const { totalHits } = results;

      console.log({ totalHits, resetPaginator });
      this.resultSize.set(totalHits);

      if (resetPaginator) {
        console.log('resetting paginator to first page');
        this.pageIndex.set(0);
        this.paginator?.firstPage();
      }
    }),
    map(({ results }) => results.ensembles),
    catchError(this.handleError),
    tap(() => {
      this.scrollToTop();
      this.loadingSearch.set(false);
    }),
  );
  ensembles = toSignal(this.ensembles$);

  emptyResults = computed(() => (this.ensembles()?.length ?? -1) === 0);

  hidePaginator = computed(() => {
    const ensembles = this.ensembles();
    const emptyResults = this.emptyResults();
    return !ensembles || emptyResults;
  });

  pageReady = computed(() => {
    const ensembles = this.ensembles();
    const loading = this.loadingSearch();
    const imagesLoaded = true; // this.imagesLoaded().length > 2;
    return ensembles !== undefined && !loading && imagesLoaded;
  });

  showFilters = computed(() => {
    const filterCategories = this.filterCategories();
    return filterCategories && filterCategories.length > 0;
  });

  constructor(
    // private loadingIndicatorService: LoadingIndicatorService,
    private ensembleService: EnsembleService,
    private navigationService: NavigationService,
    private route: ActivatedRoute,
    private search: SearchService,
    private titleService: Title,
    private scrollService: ScrollService,
    private router: Router,
    private renderer: Renderer2,
  ) {}

  searchEnsembles(
    filterCategories: FilterCategory[] | undefined,
    categoryItem: CategoryItem | undefined,
    paginatorOptions?: PaginatorOptions,
  ) {
    return this.search
      .ensemblePlp(
        this.getSearchCriteria(filterCategories, categoryItem),
        paginatorOptions,
        false,
      )
      .pipe(
        map((elasticEnsembles) => ({
          results: elasticEnsembles,
          resetPaginator: paginatorOptions === undefined,
        })),
        catchError((error) => {
          console.error('Error in EnsemblePlpResolver', error);
          return of({
            results: {
              ensembles: [],
              totalHits: 0,
            },
            resetPaginator: paginatorOptions === undefined,
          });
        }),
      );
  }

  // NOTE: if we want to keep spinner while images load, will need to use display: none instead of ngIf around the ensembles div (otherwise images will never load)
  onImageLoad(index: number) {
    // console.log('image loaded', index);
    this.imagesLoaded.update((imagesLoaded) => {
      return [...imagesLoaded, index];
    });
    // this.imagesLoaded[index] = true;
  }

  calculateMinHeight(index: number): number {
    const aspectRatio = 2 / 3;
    const elements = this.renderer
      .selectRootElement('body', true)
      .querySelectorAll('.plp-image-desk');
    const imageElement = elements[index] as HTMLElement;
    const width = imageElement ? imageElement.clientWidth : 0;
    // console.log('imageELement', imageElement);
    console.log('width', width);
    return width * aspectRatio;
  }

  // calculateMinHeight(imageElement: HTMLElement): number {
  //   const aspectRatio = 2 / 3;
  //   const width = imageElement ? imageElement.clientWidth : 0;
  //   console.log('imageElement', imageElement);
  //   console.log('width', width);
  //   return width * aspectRatio;
  // }

  clearFilters() {
    this.ensembleService.triggerEnsembleSearch.next({
      triggerSearch: true,
      resetFilterParams: true,
    });
  }

  getParamsFromFilterCategories(filterCategories: FilterCategory[]) {
    let params: { [key: string]: string } = {};

    filterCategories.forEach((category) => {
      // category.options?.forEach((option) => {
      const filterOption = this.getParamValueForFilterOption(category.options);

      if (filterOption !== '') {
        params[category.attribute] = filterOption;
      }

      // if (option.selected) {
      //   params[category.attribute] = option.value;
      // }
      // });
    });

    return params;
  }

  getParamValueForFilterOption(options: FilterOption[] | undefined) {
    if (options === undefined) {
      return '';
    }

    // console.log('options', options);

    let paramValue = '';
    let index: number = 0;

    options.forEach((option) => {
      if (option.selected) {
        if (index > 0) {
          paramValue += '|';
        }
        paramValue += option.value;
        index++;
      }
    });

    return paramValue;
  }

  updateBrowserUrlWithParams(
    params: { [key: string]: string },
    preserveKeys: string[] = [],
  ) {
    // Get the current query parameters
    const currentParams = this.route.snapshot.queryParams;

    console.log('updateBrowserUrlWithParams', params);
    console.log('currentParams', currentParams);

    // Merge current query params with new params
    const mergedParams = { ...currentParams, ...params };

    // Remove keys from mergedParams that are not in params and not in preserveKeys
    Object.keys(mergedParams).forEach((key) => {
      if (!(key in params) && !preserveKeys.includes(key)) {
        delete mergedParams[key];
      }
    });

    console.log('cleanedParams', mergedParams);

    // Create a URL tree with the cleaned parameters
    const urlTree = this.router.createUrlTree([], {
      relativeTo: this.route,
      queryParams: mergedParams,
    });

    // Replace the current URL with the updated one
    this.router.navigateByUrl(urlTree);
  }

  // updateBrowserUrlWithParams(params: { [key: string]: string }) {
  //   // Get the current URL tree

  //   console.log('UPDATE BROWSER URL WITH PARAMS', params);
  //   const urlTree = this.router.createUrlTree([], {
  //     relativeTo: this.route,
  //     queryParams: params,
  //     // queryParamsHandling: 'merge', // Merge with existing query params
  //   });

  //   // Replace the current URL with the updated one
  //   this.router.navigateByUrl(urlTree);
  // }

  setTitleMetaTag(categoryItem: CategoryItem) {
    const baseHeaderTitle: string = environment.baseHeaderTitle;

    this.titleService.setTitle(
      baseHeaderTitle + ' - ' + categoryItem.displayName,
    );
  }

  setColorClass(colorFamily: string, checkbox?: MatCheckbox): string {
    return setColorClass(colorFamily, checkbox);
  }

  private handleError(err: HttpErrorResponse): Observable<never> {
    console.log('WE ARE HANDLING LISTING-PAGE OBSERVABLE ERROR ENSEMBLE-PLP');
    console.log('Error: ', err);
    // return throwError(() => err);
    return new Observable<never>();
  }

  getImage(ensemble: EcomEnsemblePLP): Image {
    let image = getImage(ensemble);
    return image;
  }

  getImageFromImageKit(ensemble: EcomEnsemblePLP): Image {
    if (environment.imageKitPLPEnabled) {
      return getImageKitImage(ensemble);
    } else {
      return getImage(ensemble);
    }
  }

  getOriginalImage(ensemble: EcomEnsemble): Image {
    console.log(ensemble.images);
    let image = getOriginalImage(ensemble);
    console.log('original image:');
    console.log(image);
    return image;
  }

  showMobileFilters(): void {
    console.log('show mobile filters');
    this.ensembleService.showFilter.next(true);
    this.navigationService.showPlpNav.next(true);
  }

  getSearchCriteria(
    filterCategories: FilterCategory[] | undefined,
    categoryItem: CategoryItem | undefined,
  ): Array<SearchCriteria> {
    if (!filterCategories) return [];
    return this.search.getSearchCriteriaForEnsembles(
      categoryItem,
      filterCategories,
    );
  }

  getRentPrice(ensemble: EcomEnsemblePLP): number {
    return getEnsembleRentPrice(ensemble);
  }

  getBuyPrice(ensemble: EcomEnsemblePLP): number {
    return getEnsembleBuyPrice(ensemble);
  }

  async change(event: PageEvent) {
    console.log('CHANGE PAGE EVENT');
    this.paginatorChangeSubject.next(event);
  }

  getEnsembleTitleAndCode(ensemble: EcomEnsemblePLP) {
    // Replace spaces with hyphens and make the string URL-safe
    const title = encodeURIComponent(
      ensemble.title.toLowerCase().replace(/\s+/g, '-'),
    );
    const titleAndCode = `/ensemble/${title}-${ensemble.ensembleCode}`;

    // if the current URL contains query params, remove them

    return titleAndCode;
    // return url.pathname;
  }

  private scrollToTop(): void {
    this.scrollService.scrollToAnchor('listing-anchor', [0, 5000]);
  }
}
