import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, PLATFORM_ID, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AnonAuthService, AuthService } from '@jfw-library/ecommerce/core';
import { generateUUID } from 'business-logic';
import { SsrCookieService } from 'ngx-cookie-service-ssr';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'jfw-prod-login',
  templateUrl: './prod-login.component.html',
  styleUrls: ['./prod-login.component.scss'],
})
export class ProdLoginComponent implements OnInit {
  password: string | undefined;
  invalidPassword: boolean = false;
  showSpinner: boolean = false;
  isProdModeWideOpen = this.authService.isProdModeWideOpen;
  private isBrowser = isPlatformBrowser(inject(PLATFORM_ID));

  constructor(
    private cookieService: SsrCookieService,
    private anonAuthService: AnonAuthService,
    private authService: AuthService,
    private router: Router,
  ) {}
  ngOnInit(): void {
    ///---  I don't think we even want this anymore.
    // if (this.isProdModeWideOpen) {
    //   this.router.navigateByUrl('/home').then(() => {
    //     if (this.isBrowser) window.location.reload();
    //   });
    // }
  }

  async submitForm() {
    if (!this.password) {
      return;
    }

    this.showSpinner = true;

    const response = await this.anonAuthService.verifyProdMode(this.password);

    console.log('>> prod mode entry response:', response);

    if (!response.valid) {
      this.password = '';
      this.invalidPassword = true;

      this.showSpinner = false;
      return;
    }

    if (response.valid) {
      const redirect = await this.redirectHome(this.password);
    }

    this.showSpinner = false;
  }

  /**
   * Generates a random guid that is used as the session userId for the AnonToken.
   * Sets the token_refresh_key cookie to the password that was entered in the form, which will be used for future token refreshes.
   * Initializes the AnonToken.
   * If the AnonToken is successfully initialized, redirects to /home.
   * @param password The password that was entered in the form.  This is used to set the token_refresh_key cookie.
   */
  async redirectHome(password: string) {
    this.invalidPassword = false;

    let randomGuid = generateUUID(this.isBrowser);
    this.cookieService.set('token_refresh_key', password);
    const initAnonTokenSuccess =
      await this.anonAuthService.initAnonToken(randomGuid);

    if (initAnonTokenSuccess) {
      await this.router.navigateByUrl('/home');
    }
  }
}
