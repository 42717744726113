import { isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  computed,
  inject,
  PLATFORM_ID,
  Renderer2,
  signal,
  ViewChild,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import {
  NavigationService,
  ScrollService,
  SearchService,
  StyleService,
} from '@jfw-library/ecommerce/core';
import {
  canOnlyBuyStyle,
  canOnlyRentStyle,
  canRentAndBuyStyle,
  getFilterCategoriesFromQueryParams,
  getImage,
  getImageKitImage,
  getStyleBuyPrice,
  getStyleRentPrice,
  hasDisplayPrice,
} from 'business-logic';
import {
  Categories,
  CategoryItem,
  EcomStyle,
  FilterCategory,
  FilterOption,
  Image,
  PaginatorOptions,
  SearchCriteria,
} from 'common-types';
import { from, merge, Observable, of, Subject } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { StylePlpResolverData } from '../../../resolvers/style/style-plp.resolver';

@Component({
  selector: 'app-style-listing-page',
  templateUrl: './style.component.html',
  styleUrls: ['./style.component.scss'],
})
export class StyleListingPageComponent {
  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  private isFirstLoad = true;
  loading = signal(true);
  resultSize = signal(0);
  emptyResults = computed(() => this.resultSize() === 0);
  isBrowser = isPlatformBrowser(inject(PLATFORM_ID));

  private routeParams = toSignal(this.route.params);
  categoryItem = computed(() => {
    const category = this.routeParams()?.category;
    if (!category) return undefined;
    return new Categories().byRoute(category);
  });

  private queryParams = toSignal(this.route.queryParams);

  /** FilterCategories are only updated when the route params (not queryParams) change.
   * On initial page load, check if the resolver has already fetched the filterCategories.
   */
  private readonly filterCategories$ = this.route.params.pipe(
    tap(() => {
      console.log('filterCategories$');
      this.loading.set(true);
    }),
    switchMap((params) => {
      const queryParams = this.queryParams();
      const categoryItem = this.categoryItem();
      const resolverData = this.route.snapshot.data.resolverData as
        | StylePlpResolverData
        | undefined;
      const preFetchedFilterCategories =
        resolverData?.filterCategoriesData?.filterCategories;
      const resolverCategoryItem =
        resolverData?.filterCategoriesData?.categoryItem;
      const useResolverData =
        resolverCategoryItem?.category === categoryItem?.category &&
        preFetchedFilterCategories;
      if (useResolverData) {
        console.log('Using filterCategories resolver data');
        const selectedFilters = getFilterCategoriesFromQueryParams(
          queryParams,
          preFetchedFilterCategories,
        );
        return of(selectedFilters);
      }
      console.log('Not using filterCategories resolver data.');
      return from(
        this.search.getFilterCategoriesForStylePlp(categoryItem),
      ).pipe(
        map((filterCategories) =>
          getFilterCategoriesFromQueryParams(queryParams, filterCategories),
        ),
        catchError((error) => {
          console.error('Error fetching filterCategories', error);
          return [];
        }),
      );
    }),
    tap((filters) => {
      // pass filterCategories to filters
      this.styleService.filterCategories.next(filters);
    }),
    shareReplay(1),
    catchError((error) => {
      console.error('Error fetching filterCategories', error);
      return of([]);
    }),
  );

  private filterCategories = toSignal(this.filterCategories$, {
    initialValue: [],
  });

  private paginatorChangeSubject = new Subject<PageEvent>();
  private paginatorChange$ = this.paginatorChangeSubject.asObservable().pipe();
  private paginatorChange = toSignal(this.paginatorChange$);
  pageSize = computed(() => this.paginatorChange()?.pageSize ?? 24);
  pageIndex = computed(() => this.paginatorChange()?.pageIndex ?? 0);

  private readonly searchFromPaginator$ = this.paginatorChangeSubject.pipe(
    tap(() => console.log('paginatorChange$ triggering styles$')),
    map((pageEvent) => ({
      resetPaginator: false, // paginator trigger is only time we don't reset the paginator
      filterCategories: this.filterCategories(),
      pageEvent,
    })),
  );

  private clearFiltersSubject = new Subject<void>();

  private readonly searchFromParams$ = this.filterCategories$.pipe(
    tap(() => console.log('searchFromParams$ triggering styles$')),
    map((filterCategories) => ({
      resetPaginator: true,
      filterCategories,
      pageEvent: undefined,
    })),
  );

  private readonly searchFromSearchStyles$ =
    this.styleService.searchStyles.pipe(
      // debounceTime(300), // allow for multiple selections to be made before searching
      tap(() => {
        const filterCategories = this.filterCategories();
        console.log('searchStyles triggering updateBrowserUrlWithParams');
        if (filterCategories) {
          const filterParams =
            this.getParamsFromFilterCategories(filterCategories);
          this.updateBrowserUrlWithParams(filterParams, ['event']);
        } else {
          this.updateBrowserUrlWithParams({});
        }
      }),
      map(() => ({
        resetPaginator: true,
        filterCategories: this.filterCategories(),
        pageEvent: undefined,
      })),
      shareReplay(1),
    );

  private readonly searchFromClearFilters$ = this.clearFiltersSubject
    .asObservable()
    .pipe(
      tap(() => console.log('clearFiltersSubject triggering styles$')),
      map(() => ({
        resetPaginator: true,
        filterCategories: this.filterCategories(),
        pageEvent: undefined,
      })),
      shareReplay(1),
    );

  /** Fetch styles from elastic anytime a trigger source emits
   * Only the paginator event will have a PageEvent object, all others will be undefined
   */
  private getStylesTriggers$ = merge(
    this.searchFromParams$,
    this.searchFromSearchStyles$,
    this.searchFromClearFilters$,
    this.searchFromPaginator$,
  ).pipe(shareReplay(1));

  private styles$ = this.getStylesTriggers$.pipe(
    switchMap(({ filterCategories, resetPaginator, pageEvent }) => {
      if (this.isFirstLoad) {
        this.isFirstLoad = false;
        // First time rendering page (so either fetch styles or use transfer state)
        console.log(
          'First time rendering page.  Checking for styles resolver data',
        );
        const resolverData = this.route.snapshot.data.resolverData as
          | StylePlpResolverData
          | undefined;
        const { searchResults } = resolverData ?? {};
        const { styles, resultsCount } = searchResults ?? {};
        if (styles) {
          console.log('Using styles resolver data');
          this.resultSize.set(resultsCount ?? 0);
          return of({ styles, resetPaginator });
        } else {
          console.log('No resolver data.');
        }
      } else {
        console.log('This is not the first time rendering the page.');
      }

      console.log('Fetching styles...');

      const categoryItem = this.categoryItem();
      if (!categoryItem) {
        this.resultSize.set(0);
        return of({ styles: [], resetPaginator: false });
      }
      const searchCriteria = this.getSearchCriteria(
        categoryItem,
        filterCategories,
      );
      const paginatorOptions: PaginatorOptions | undefined = pageEvent
        ? {
            previousPageIndex: pageEvent?.previousPageIndex,
            pageIndex: pageEvent.pageIndex,
            pageSize: pageEvent.pageSize,
            length: pageEvent.length,
          }
        : undefined;

      return this.search.style(searchCriteria, paginatorOptions).pipe(
        map((searchResults) => {
          const emptyResults =
            (searchResults as any)?.hits?.total?.value ?? 0 === 0;
          const resetPaginator = !paginatorOptions && !emptyResults;
          if (!searchResults) {
            this.resultSize.set(0);
            return {
              styles: [],
              resetPaginator,
            };
          }
          const styles = searchResults.hits.hits.map(
            (hit: any) => hit._source as EcomStyle,
          );
          const resultsCount = (searchResults as any)?.hits?.total?.value ?? 0;
          this.resultSize.set(resultsCount);
          return {
            styles,
            resetPaginator,
          };
        }),
        catchError((error) => {
          console.error('Error fetching styles', error);
          this.resultSize.set(0);
          return of({ styles: [], resetPaginator: false });
        }),
      );
    }),
    tap(({ styles, resetPaginator }) => {
      console.log('searchResults returned');
      if (this.paginator && resetPaginator) {
        console.log('calling paginator.firstPage()');
        this.paginator.firstPage();
      }
      this.loading.set(false);
      this.scrollToTop();
    }),
    map(({ styles }) => styles),
    catchError(this.handleError),
  );
  styles = toSignal(this.styles$);

  getStylesTriggered = toSignal(this.getStylesTriggers$);
  filterCategoriesSelected = computed(() => {
    const filterCategories = this.filterCategories();
    const getStylesTriggered = this.getStylesTriggered(); // changes to the filterCategories object (by the filters) are mutations, so will not trigger this computed.  Using this to trigger the computed.
    console.log('filterCategoriesSelected triggered');
    return filterCategories.some((category) =>
      category.options?.some((option) => option.selected),
    );
  });
  showFilters = computed(() => {
    const filterCategories = this.filterCategories();
    return filterCategories && filterCategories.length > 0;
  });

  constructor(
    private styleService: StyleService,
    private navigationService: NavigationService,
    private route: ActivatedRoute,
    private search: SearchService,
    private scrollService: ScrollService,
    private router: Router,
    private renderer: Renderer2,
  ) {}

  calculateMinHeight(index: number): number {
    const aspectRatio = 2 / 3;
    const elements = this.renderer
      .selectRootElement('body', true)
      .querySelectorAll('.plp-image-desk');
    const imageElement = elements[index] as HTMLElement;
    const width = imageElement ? imageElement.clientWidth : 0;
    // console.log('imageELement', imageElement);
    console.log('width', width);
    return width * aspectRatio;
  }

  // calculateMinHeight(index: number): number {
  //   const aspectRatio = 2 / 3;
  //   const imageElement = this.renderer.selectRootElement(
  //     '.plp-image-desk',
  //     true,
  //   )[index] as HTMLElement;
  //   const width = imageElement ? imageElement.clientWidth : 0;
  //   return width * aspectRatio;
  // }

  clearFilters() {
    this.filterCategories().forEach((category) => {
      category.options?.forEach((option) => {
        option.selected = false;
      });
    });

    this.updateBrowserUrlWithParams({}, ['event']);

    this.styleService.filterCategories.next(this.filterCategories());

    this.clearFiltersSubject.next();
  }

  private handleError(err: HttpErrorResponse): Observable<never> {
    console.log('WE ARE HANDLING LISTING-PAGE OBSERVABLE ERROR STYLE-PDP');
    console.log('Error: ', err);
    // return throwError(() => err);
    return new Observable<never>();
  }

  private getSearchCriteria(
    categoryItem: CategoryItem,
    filterCategories: FilterCategory[],
  ): SearchCriteria[] {
    console.log('getSearchCriteria() > categoryItem', categoryItem);
    return this.search.getSearchCriteriaForStyles(
      categoryItem,
      filterCategories,
    );
  }

  public getImageFromImageKit(style: EcomStyle): Image {
    // return getImage(style);
    if (environment.imageKitPLPEnabled) {
      return getImageKitImage(style);
    } else {
      return getImage(style);
    }
  }

  public showMobileFilters(): void {
    this.styleService.showFilter.next(true);
    this.navigationService.showPlpNav.next(true);
  }

  public getRentPrice(style: EcomStyle): number {
    return getStyleRentPrice(style) ?? 0;
  }

  public getBuyPrice(style: EcomStyle): number {
    return getStyleBuyPrice(style) ?? 0;
  }

  public hasDisplayPrice(ecomStyle: EcomStyle): boolean {
    return hasDisplayPrice(ecomStyle);
  }

  public isPurchaseOnly(ecomStyle: EcomStyle): boolean {
    return canOnlyBuyStyle(ecomStyle);
  }

  public displayRentText(ecomStyle: EcomStyle): string {
    if (canRentAndBuyStyle(ecomStyle)) {
      return 'AVAILABLE TO RENT OR BUY';
    } else {
      if (canOnlyRentStyle(ecomStyle)) {
        return 'AVAILABLE TO RENT';
      }
    }
    return '';
  }

  public onPaginatorChange(event: PageEvent) {
    this.paginatorChangeSubject.next(event);
  }

  getStyleTitleAndCode(style: EcomStyle) {
    // Replace spaces with hyphens and make the string URL-safe
    if (style.marketingTitle !== undefined) {
      const title = encodeURIComponent(
        style.marketingTitle.toLowerCase().replace(/\s+/g, '-'),
      );
      const titleAndCode = `/style/${title}-${style.styleCode}`;
      return titleAndCode;
    } else {
      return `/style/${style.styleCode}`;
    }
  }

  getParamsFromFilterCategories(filterCategories: FilterCategory[]) {
    let params: { [key: string]: string } = {};

    filterCategories.forEach((category) => {
      // category.options?.forEach((option) => {
      const filterOption = this.getParamValueForFilterOption(category.options);

      if (filterOption !== '') {
        params[category.attribute] = filterOption;
      }

      // if (option.selected) {
      //   params[category.attribute] = option.value;
      // }
      // });
    });

    return params;
  }

  getParamValueForFilterOption(options: FilterOption[] | undefined) {
    if (options === undefined) {
      return '';
    }

    // console.log('options', options);

    let paramValue = '';
    let index: number = 0;

    options.forEach((option) => {
      if (option.selected) {
        if (index > 0) {
          paramValue += '|';
        }
        paramValue += option.value;
        index++;
      }
    });

    return paramValue;
  }

  updateBrowserUrlWithParams(
    params: { [key: string]: string },
    preserveKeys: string[] = [],
  ) {
    // Get the current query parameters
    const currentParams = this.route.snapshot.queryParams;

    console.log('updateBrowserUrlWithParams', params);
    console.log('currentParams', currentParams);

    // Merge current query params with new params
    const mergedParams = { ...currentParams, ...params };

    // Remove keys from mergedParams that are not in params and not in preserveKeys
    Object.keys(mergedParams).forEach((key) => {
      if (!(key in params) && !preserveKeys.includes(key)) {
        delete mergedParams[key];
      }
    });

    console.log('cleanedParams', mergedParams);

    // Create a URL tree with the cleaned parameters
    const urlTree = this.router.createUrlTree([], {
      relativeTo: this.route,
      queryParams: mergedParams,
    });

    // Replace the current URL with the updated one
    this.router.navigateByUrl(urlTree);
  }

  private scrollToTop(): void {
    this.scrollService.scrollToAnchor('listing-anchor', [0, 5000]);
  }
}
