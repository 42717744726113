import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AuthService } from '@jfw-library/ecommerce/core';
import {
  SignInFormControls,
  ZodFormUtilities,
} from '@jfw-library/ecommerce/zod-forms';
import { SignInFormSchema, Site } from 'common-types';

export interface UserGoogleProfile {
  uid?: string;
  email?: string;
  displayName?: string;
  photoURL?: string;
  disabled?: boolean;
  customClaims?: any;
  disabling?: boolean;
}

@Component({
  selector: 'app-email-password-form',
  templateUrl: './email-password-form.component.html',
  styleUrls: ['./email-password-form.component.scss'],
})
export class EmailPasswordFormComponent {
  signInForm = new FormGroup<SignInFormControls>(
    {
      email: new FormControl('', {
        nonNullable: true,
      }),
      password: new FormControl('', {
        nonNullable: true,
      }),
    },
    [ZodFormUtilities.zodFormGroupValidator(SignInFormSchema)],
  );

  constructor(public authService: AuthService) {}

  onSignIn(): void {
    if (
      this.signInForm.valid &&
      this.signInForm.value.email &&
      this.signInForm.value.password
    ) {
      this.authService.signInWithEmailAndPassword(
        Site.Ecom,
        this.signInForm.value.email,
        this.signInForm.value.password,
        '/',
      );
    }
  }
}
