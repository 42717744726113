import { isPlatformServer } from '@angular/common';
import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SearchService } from '@jfw-library/ecommerce/core';
import { getFilterCategoriesFromQueryParams } from 'business-logic';
import { Categories, EcomStyle } from 'common-types';
import { catchError, firstValueFrom, from, map, of } from 'rxjs';
import { SEOService } from '../../services/seo/seo-service';
import { TransferStateService } from '../../services/transfer-state/transfer-state.service';
import {
  FILTER_CATEGORIES_SEARCH_RESULTS,
  FilterCategoriesSearchResults,
} from '../../transfer-state-keys/filter-categories-results.key';
import { IS_PRE_RENDERED } from '../../transfer-state-keys/is-prerendered.key';
import {
  SEARCH_RESULTS_FOR_STYLES_PLP_KEY,
  SearchResultsForStylesPlp,
} from '../../transfer-state-keys/search-results-for-styles-plp.key';

type MetaInfo = {
  metaDescription: string | undefined;
  metaTitle: string | undefined;
  metaKeywords: string | undefined;
  noIndex: boolean | undefined;
};

interface Resolve {
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any;
}

export type StylePlpResolverData = {
  searchResults: SearchResultsForStylesPlp;
  filterCategoriesData: FilterCategoriesSearchResults;
};
@Injectable({ providedIn: 'root' })
export class StylePlpResolver implements Resolve {
  private seoService = inject(SEOService);
  private search = inject(SearchService);
  private transferState = inject(TransferStateService);
  private categories = new Categories();
  private isServer = isPlatformServer(inject(PLATFORM_ID));
  constructor() {}
  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const categoryParam = route.paramMap.get('category');
    if (!categoryParam) {
      return; // maybe route to home???
    }
    const metaData: MetaInfo = {
      metaTitle: categoryParam ?? undefined,
      metaKeywords: undefined,
      metaDescription: undefined,
      noIndex: true,
    };

    switch (categoryParam) {
      case 'shirts':
        metaData.metaTitle = 'Dress Shirts';
        metaData.metaDescription =
          'Discover the epitome of elegance with our premium dress shirts. Impeccable craftsmanship, timeless styles, and superior comfort. Upgrade your wardrobe today!';
        metaData.noIndex = false;
        break;
      case 'ties':
        metaData.metaTitle = 'Ties and Bowties';
        metaData.metaDescription =
          'Elevate your style with our exquisite ties. Explore a collection of 400 colors and modern patterns for the perfect finishing touch. Shop now and redefine your look.';
        metaData.noIndex = false;
        break;
      case 'vests-and-cummerbunds':
        metaData.metaTitle = 'Vests and Cummerbunds';
        metaData.noIndex = false;
        metaData.metaDescription =
          'Elevate your formal look with stylish vests and cummerbunds. Explore classic and contemporary designs to find the perfect accessory. Shop now and redefine your style!';
        break;
      case 'shoes-and-socks':
        metaData.metaTitle = 'Dress Shoes and Socks';
        metaData.noIndex = false;
        metaData.metaDescription =
          'Step up your style game with our trendy collection of shoes and socks. Explore a variety of designs and materials for a fashionable and comfortable look. Shop now!';
        break;

      case 'cufflinks-and-studs':
        metaData.metaTitle = 'Cufflinks and Studs';
        metaData.noIndex = false;
        metaData.metaDescription =
          'The smallest details matter so enhance your formal attire with our elegant cufflinks and studs for the perfect finishing touch. Shop now to elevate your look!';
        break;

      case 'accessories':
        metaData.metaTitle = 'Formal Wear Accessories';
        metaData.noIndex = false;
        metaData.metaDescription =
          'Complete your formal look with our exquisite suit and tuxedo accessories. Shop now for stylish ties, cufflinks, and pocket squares. Elevate your style!';
        break;
      default:
        metaData.noIndex = true;
        break;
    }

    const categoryItem = this.categories.byRoute(categoryParam);
    if (!categoryItem) {
      return false;
    }

    const category = categoryItem?.category;

    const { queryParams } = route;
    const isParameterizedRoute = Object.keys(queryParams).length > 0;
    const isPreRendered = this.transferState.get(IS_PRE_RENDERED, true); // default to true if not set to err on the side of caution

    const filterCategoriesTransferState = this.transferState.get(
      FILTER_CATEGORIES_SEARCH_RESULTS,
      null,
    );

    /** The FilterCategories and their options are always the same, regardless of query params,
     * so we can use the TransferState data even if the page was pre-rendered.
     * Just need to make sure the right options are selected based on the query params,
     * but that happens after we get the data.
     */
    const useFilterCategoriesTransferStateData =
      !this.isServer && // only use transfer state on the browser
      filterCategoriesTransferState !== null &&
      filterCategoriesTransferState.categoryItem.category ===
        categoryItem.category; // categoryItem.category will be different when a navigation triggers this resolver (as opposed to initial page load)

    console.log(
      'Use data from filterCategories Transfer State:',
      useFilterCategoriesTransferStateData,
    );

    const filterCategoriesSource$ = useFilterCategoriesTransferStateData
      ? of(filterCategoriesTransferState)
      : from(this.search.getFilterCategoriesForStylePlp(categoryItem)).pipe(
          map((filterCategories) => {
            return {
              filterCategories,
              categoryItem,
            } satisfies FilterCategoriesSearchResults;
          }),
          catchError((error) => {
            console.error('Error in StylePlpResolver', error);
            return of({
              filterCategories: [],
              categoryItem,
            } satisfies FilterCategoriesSearchResults);
          }),
        );

    const filterCategoriesData = await firstValueFrom(
      filterCategoriesSource$.pipe(
        map(({ filterCategories, categoryItem }) => {
          const selectedCategories = getFilterCategoriesFromQueryParams(
            queryParams,
            filterCategories,
          );
          return {
            filterCategories: selectedCategories,
            categoryItem,
          } satisfies FilterCategoriesSearchResults;
        }),
      ),
    );
    const { filterCategories } = filterCategoriesData;

    if (this.isServer && filterCategories.length > 0) {
      this.transferState.set(
        FILTER_CATEGORIES_SEARCH_RESULTS,
        filterCategoriesData,
      );
    }

    const searchCriteria = this.search.getSearchCriteriaForStyles(
      categoryItem,
      filterCategories,
    );

    // just defining the search here.  This may or may not be called below.
    const search$ = this.search.style(searchCriteria).pipe(
      map((elasticStyles) => {
        const styles: EcomStyle[] = [];
        const recastedElasticStylesType: any = elasticStyles.hits.total;
        // To fix later
        const resultsCount = recastedElasticStylesType.value as number;
        if (elasticStyles.hits.hits.length > 0) {
          // this.resultSize = resultsCount;
          elasticStyles.hits.hits.forEach((style: any) => {
            styles.push(style._source as EcomStyle);
          });
        }
        return {
          styles,
          resultsCount,
          categoryItem,
        } satisfies SearchResultsForStylesPlp;
      }),
      catchError((error) => {
        console.error('Error in StylePlpResolver', error);
        return of({
          styles: [],
          resultsCount: 0,
          categoryItem,
        } satisfies SearchResultsForStylesPlp);
      }),
    );

    console.log('Search Criteria:', searchCriteria);

    const searchResultsTransferState = this.transferState.get(
      SEARCH_RESULTS_FOR_STYLES_PLP_KEY,
      null,
    );

    // console.log(
    //   'Search Results Transfer State resultsCount:',
    //   searchResultsTransferState?.resultsCount,
    // );

    /** The source of the searchResults changes depending on context (server/browser),
     * TransferState availability (might be disabled), and whether the data is already in TransferState.
     *
     * Need to handle several scenarios:
     * 1. Never use/read TransferState during SSR/SSG because it hasn't been set yet.
     * 2. We only want to use TransferState on the first page load, not subsequent navigations,
     *   so we need to check if the category in the TransferState matches the current category.
     * 3. Firebase Hosting will always ignore query params and return the base page without any filtering,
     * so we first need to know if we have a page that came from Hosting (prerendered/SSG) or from SSR.
     *  If it is prerendered, we can't use the TransferState data because it is not filtered.
     *  If it is SSR, we can use the TransferState data because it was properly filtered in SSR function.
     */
    const getSearchResults = async () => {
      if (this.isServer) {
        console.log(
          'Server StylePlpResolver.  Fetching styles from Elastic API',
        );
        return firstValueFrom(search$);
      }

      // only return transferState on the browser
      console.log('Browser StylePlpResolver');

      // if the page was pre-rendered and has query params, we can't use TransferState because it is not filtered
      if (isPreRendered && isParameterizedRoute) {
        console.log(
          'Parameterize route that was pre-rendered.  Fetching Styles from Elastic API',
        );
        return firstValueFrom(search$);
      }

      if (!!searchResultsTransferState) {
        console.log('Using Styles TransferState');
        return searchResultsTransferState;
      }
      console.log('No TransferState Found. Fetching styles from Elastic API');
      return firstValueFrom(search$);
    };

    const searchResults = await getSearchResults();
    // only set TransferState if we actually have some styles on the server
    if (this.isServer && searchResults.styles.length > 0) {
      console.log(
        'Search Results ',
        'resultsCount: ',
        searchResults.resultsCount,
        ' numStyles: ',
        searchResults.styles.length,
      );

      this.transferState.set(SEARCH_RESULTS_FOR_STYLES_PLP_KEY, searchResults);
    }

    // Check if it is a parameterized route (we do not index paramterized routes)

    if (isParameterizedRoute) {
      this.seoService.setNoIndexTag(true);
      /**
       * If noIndex is ever true, canonical should be removed
       * Default remove canonical.
       *  */
      this.seoService.setCanonicalTag(state.url, false);
    } else {
      this.seoService.setNoIndexTag(metaData?.noIndex ?? undefined);
      this.seoService.setCanonicalTag(state.url, !metaData.noIndex);
    }
    this.seoService.setMetaTags(metaData);
    // route.data = { seoParams: metaData };

    return {
      searchResults,
      filterCategoriesData,
    } satisfies StylePlpResolverData;
  }
}
