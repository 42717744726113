<div class="header">
  <div class="menu-items">
    <button mat-icon-button (click)="onSidenav()">
      <mat-icon>menu</mat-icon>
    </button>

    <button mat-icon-button title="Search" (click)="onSearch()">
      <mat-icon>search</mat-icon>
    </button>
  </div>

  <div class="home">
    <a routerLink="/">
      <img
        src="assets/images/jfw-logos/logo.png"
        alt="Logo for Jim's Formal Wear"
        class="logo"
      />
    </a>
  </div>

  <div class="accounts">
    @if (isBrowser && user() !== undefined) {
      @if (user() !== null && isLoggedIn()) {
        <button
          mat-icon-button
          class="account-button"
          routerLink="/my-account/events"
          title="My Account"
          rel="nofollow"
        >
          <div>
            <span>{{ firstLetter() }}</span>
          </div>
        </button>
      } @else {
        <button
          mat-icon-button
          title="Guest - Click to Login"
          (click)="onAnon()"
          rel="nofollow"
        >
          <mat-icon>account_circle</mat-icon>
        </button>
      }
    }
  </div>
</div>
<app-search-mobile></app-search-mobile>
