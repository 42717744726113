import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Params,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { SearchService } from '@jfw-library/ecommerce/core';
import { getEnsembleDesigner } from 'business-logic';
import { EcomEnsemble, SearchCriteria } from 'common-types';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { SEOService } from '../../services/seo/seo-service';
import { TransferStateService } from '../../services/transfer-state/transfer-state.service';
import { ENSEMBLE_DETAIL_KEY } from '../../transfer-state-keys/ensemble-detail.key';
type MetaInfo = {
  metaDescription: string | undefined;
  metaTitle: string | undefined;
  metaKeywords: string | undefined;
  noIndex: boolean | undefined;
};

export type EnsemblePdpResolverData = EcomEnsemble[];

interface Resolve {
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any;
}
@Injectable({ providedIn: 'root' })
export class EnsemblePdpResolver implements Resolve {
  private searchService = inject(SearchService);
  private seoService = inject(SEOService);
  private router = inject(Router);
  private isBrowser = isPlatformBrowser(inject(PLATFORM_ID));
  private isServer = isPlatformServer(inject(PLATFORM_ID));
  private transferState = inject(TransferStateService);
  constructor() {}

  private getFilteredDescriptionForMeta(
    description: string | undefined,
  ): string {
    if (description) {
      //let filteredDescription = description.replace(/[#-]/g, '');
      let descriptionWithoutLineBreaks = description.replace(/\n|\r/g, '');
      let descriptionWithoutHashes = descriptionWithoutLineBreaks.replace(
        /[#-]/g,
        '',
      );
      return descriptionWithoutHashes.substring(0, 160);
    }
    return '';
  }

  private getSearchCriteria(params: Params): Array<SearchCriteria> {
    const { ensembleCode, ensembleGroupCode } = params;
    let searchCriteria: Array<SearchCriteria> = [];
    let ensembleGroupCodeSearch = {
      index: 'ecom-ensembles',
      attribute: 'ensembleGroupCode',
      values: [ensembleCode],
    };
    searchCriteria.push(ensembleGroupCodeSearch);
    return searchCriteria;
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<EnsemblePdpResolverData> {
    let ensembleCodeString: string = state.url;
    // console.log('ensembleCodeString', ensembleCodeString);
    let ensembleCodeArray = ensembleCodeString.split('-');
    let setMetaTags = true;
    const updatedParams = {
      ensembleCode: ensembleCodeArray[ensembleCodeArray.length - 1],
    };
    // console.log(
    //   'ensembleCodeArray BEFORE: ' + JSON.stringify(ensembleCodeArray),
    // );
    // console.log('updatedParams BEFORE', updatedParams);

    // This if statement is to prevent the excess api call from products search page
    if (
      ensembleCodeArray[ensembleCodeArray.length - 1].includes('searchText')
    ) {
      ensembleCodeArray = ensembleCodeString.split('/');
      updatedParams.ensembleCode =
        ensembleCodeArray[ensembleCodeArray.length - 1].split('?')[0];
      setMetaTags = false;
    } else if (ensembleCodeString.includes('-')) {
      // Entered from PLP
      updatedParams.ensembleCode =
        ensembleCodeArray[ensembleCodeArray.length - 1];
      setMetaTags = true;
    } else {
      // Entered from color chips?
      ensembleCodeArray = ensembleCodeString.split('/');
      updatedParams.ensembleCode =
        ensembleCodeArray[ensembleCodeArray.length - 1];
      setMetaTags = false;
    }

    const ensemblesFromTransferState = this.transferState.get(
      ENSEMBLE_DETAIL_KEY,
      null,
    );

    const useTransferStateData =
      this.isBrowser &&
      ensemblesFromTransferState !== null &&
      ensemblesFromTransferState.some(
        (ens) => ens.ensembleCode === updatedParams.ensembleCode,
      ); // ensembleCode will be different when a navigation triggers this resolver (as opposed to initial page load)

    console.log('Use data from TransferState:', useTransferStateData);
    // console.log('ensembleCodeArray AFTER: ', ensembleCodeArray);
    // console.log('updatedParams AFTER', updatedParams);

    const searchCriteria = this.getSearchCriteria(updatedParams);
    // console.log('searchCriteria', searchCriteria);

    // just defining the search here.  This may or may not be called below, depending on the context and presence of TransferState data.
    const ensemblesFromApi$ = this.searchService.ensemble(searchCriteria).pipe(
      map((elasticEnsembles) => {
        // console.log(elasticEnsembles.hits.hits);
        const ensembles: EcomEnsemble[] =
          elasticEnsembles.hits.hits.map(
            (hit) => hit._source as EcomEnsemble,
          ) ?? [];
        if (this.isServer) {
          this.transferState.set(ENSEMBLE_DETAIL_KEY, ensembles);
        }
        return ensembles;
      }),
      catchError((error) => {
        console.error('Ensemble Resolver Error: ' + JSON.stringify(error));
        return of([]);
      }),
    );

    const ensemblesSource$ = useTransferStateData
      ? of(ensemblesFromTransferState)
      : ensemblesFromApi$;

    return ensemblesSource$.pipe(
      tap((ensembles) => {
        if (!ensembles || ensembles.length === 0) {
          console.log('No Ensembles Found');
        } else {
          console.log(
            'ensembles received: ',
            ensembles?.map((ens) => ens.ensembleCode),
          );
          const ensemble = ensembles[0];
          const title = getEnsembleDesigner(ensemble) + ' ' + ensemble.title;
          let metaDescription = ensemble.metaDescription;
          if (
            metaDescription === undefined ||
            metaDescription === null ||
            metaDescription === ''
          ) {
            metaDescription = this.getFilteredDescriptionForMeta(
              ensemble.description,
            );
          }

          const keywords = ensemble.keywords
            ? ensemble.keywords.join(',')
            : undefined;

          const metaInfo: MetaInfo = {
            metaDescription,
            metaTitle: title,
            noIndex: false,
            metaKeywords: keywords,
          };

          // Check if it is a parameterized route (we do not index paramterized routes)
          const { queryParams } = route;
          const isParameterizedRoute = Object.keys(queryParams).length > 0;

          if (isParameterizedRoute) {
            this.seoService.setNoIndexTag(true);
            /**
             * If noIndex is ever true, canonical should be removed
             * Default remove canonical.
             *  */
            this.seoService.setCanonicalTag(state.url, false);
          } else {
            this.seoService.setNoIndexTag(metaInfo?.noIndex ?? undefined);
            this.seoService.setCanonicalTag(state.url, !metaInfo.noIndex);
          }
          this.seoService.setMetaTags(metaInfo);
        }
      }),
      catchError((error) => {
        console.error('Ensemble Resolver Error: ' + JSON.stringify(error));
        return of([]);
      }),
    );
  }
}
