import { isPlatformServer } from '@angular/common';
import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SearchService } from '@jfw-library/ecommerce/core';
import {
  getFilterCategoriesByEnsemble,
  getFilterCategoriesFromQueryParams,
} from 'business-logic';
import { Categories } from 'common-types';
import { catchError, firstValueFrom, map, Observable, of } from 'rxjs';
import { SEOService } from '../../services/seo/seo-service';
import { TransferStateService } from '../../services/transfer-state/transfer-state.service';
import {
  FILTER_CATEGORIES_SEARCH_RESULTS,
  FilterCategoriesSearchResults,
} from '../../transfer-state-keys/filter-categories-results.key';
import { IS_PRE_RENDERED } from '../../transfer-state-keys/is-prerendered.key';
import {
  SEARCH_RESULTS_FOR_ENSEMBLES_PLP_KEY,
  SearchResultsForEnsemblesPlp,
} from '../../transfer-state-keys/search-results-for-ensemble-plp.key';

type MetaInfo = {
  metaDescription: string | undefined;
  metaTitle: string | undefined;
  metaKeywords: string | undefined;
  noIndex: boolean | undefined;
};

export type EnsemblePlpResolverData = {
  searchResults: SearchResultsForEnsemblesPlp;
  filterCategoriesData: FilterCategoriesSearchResults;
};

interface Resolve {
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any;
}
@Injectable({ providedIn: 'root' })
export class EnsemblePlpResolver implements Resolve {
  private seoService = inject(SEOService);
  private search = inject(SearchService);
  private transferState = inject(TransferStateService);
  private categories = new Categories();
  private isServer = isPlatformServer(inject(PLATFORM_ID));
  constructor() {}
  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const categoryParam = route.paramMap.get('category');
    if (!categoryParam) {
      return; // maybe route to home???
    }
    const { queryParams } = route;
    console.log('Query Params:', queryParams);
    const isParameterizedRoute = Object.keys(queryParams).length > 0;

    const metaData: MetaInfo = {
      metaTitle: categoryParam ?? undefined,
      metaKeywords: undefined,
      metaDescription: undefined,
      noIndex: true,
    };

    // there is currently only one category, suits-and-tuxedos
    switch (categoryParam) {
      case 'suits-and-tuxedos':
        metaData.metaTitle = 'Suit and Tuxedos';
        metaData.metaDescription =
          'Discover hundreds of suit and tuxedo combinations for your next event. Rent online or at over 4,500 locations. Find your perfect look with top national brands.';
        metaData.noIndex = false;
        break;
      default:
        metaData.noIndex = true;
        break;
    }

    const categoryItem = this.categories.byRoute(categoryParam);
    if (!categoryItem) {
      return false;
    }

    const filterCategoriesTransferState = this.transferState.get(
      FILTER_CATEGORIES_SEARCH_RESULTS,
      null,
    );

    const isPreRendered = this.transferState.get(IS_PRE_RENDERED, true); // default to true if not set to err on the side of caution

    const useFilterCategoriesTransferStateData =
      !this.isServer && // only use transfer state on the browser
      filterCategoriesTransferState !== null &&
      filterCategoriesTransferState.categoryItem.category ===
        categoryItem.category; // categoryItem.category will be different when a navigation triggers this resolver (as opposed to initial page load)

    console.log(
      'Use data from filterCategories Transfer State:',
      useFilterCategoriesTransferStateData,
    );

    const baseCategories = getFilterCategoriesByEnsemble();

    const filterCategoriesSource$ = useFilterCategoriesTransferStateData
      ? of(filterCategoriesTransferState)
      : this.search
          .getDistinctFiltersByCategories(
            'ecom-ensembles',
            categoryItem.category,
            baseCategories,
          )
          .pipe(
            map((filterCategories) => {
              return {
                filterCategories,
                categoryItem,
              } satisfies FilterCategoriesSearchResults;
            }),
            catchError((error) => {
              console.error(
                'Error in EnsemblePlpResolver filterCategories',
                error,
              );
              return of({
                filterCategories: [],
                categoryItem,
              } satisfies FilterCategoriesSearchResults);
            }),
          );

    const filterCategoriesData = await firstValueFrom(
      filterCategoriesSource$.pipe(
        map(({ filterCategories, categoryItem }) => {
          // update filterCategories with selected categories from query params
          const selectedCategories = getFilterCategoriesFromQueryParams(
            queryParams,
            filterCategories,
          );

          // console.log(
          //   'Selected Categories:',
          //   JSON.stringify(selectedCategories),
          // );
          return {
            filterCategories: selectedCategories,
            categoryItem,
          } satisfies FilterCategoriesSearchResults;
        }),
      ),
    );
    const { filterCategories } = filterCategoriesData;

    if (this.isServer && filterCategories.length > 0) {
      this.transferState.set(
        FILTER_CATEGORIES_SEARCH_RESULTS,
        filterCategoriesData,
      );
    }

    const searchCriteria = this.search.getSearchCriteriaForEnsembles(
      categoryItem,
      filterCategories,
    );
    // just defining the search here.  This may or may not be called below.
    const search$: Observable<SearchResultsForEnsemblesPlp> = this.search
      .ensemblePlp(searchCriteria)
      .pipe(
        map((searchResponse) => {
          const totalHits = searchResponse.totalHits;
          const ensembles =
            searchResponse?.ensembles && searchResponse.ensembles.length > 0
              ? searchResponse.ensembles
              : [];
          return {
            ensembles,
            totalHits,
            categoryItem,
          };
        }),
        catchError((error) => {
          console.error('Error in EnsemblePlpResolver', error);
          return of({
            ensembles: [],
            totalHits: 0,
            categoryItem,
          } satisfies SearchResultsForEnsemblesPlp);
        }),
      );

    console.log('Search Criteria:', searchCriteria);

    const searchResultsTransferState = this.transferState.get(
      SEARCH_RESULTS_FOR_ENSEMBLES_PLP_KEY,
      null,
    );

    // console.log(
    //   'Search Results Transfer State totalHits:',
    //   searchResultsTransferState?.totalHits,
    // );

    /** The source of the searchResults changes depending on context (server/browser),
     * TransferState availability (might be disabled), and whether the data is already in TransferState.
     *
     * Need to handle several scenarios:
     * 1. Never use/read TransferState during SSR/SSG because it hasn't been set yet.
     * 2. We only want to use TransferState on the first page load, not subsequent navigations,
     *   so we need to check if the category in the TransferState matches the current category.
     * 3. Firebase Hosting will always ignore query params and return the base page without any filtering,
     * so we first need to know if we have a page that came from Hosting (prerendered/SSG) or from SSR.
     *  If it is prerendered, we can't use the TransferState data because it is not filtered.
     *  If it is SSR, we can use the TransferState data because it was properly filtered in SSR function.
     */
    const getSearchResults = async () => {
      if (this.isServer) {
        // always fetch on the server
        console.log('Server EnsemblePlpResolver');
        console.log('Fetching Ensembles from API');
        return firstValueFrom(search$);
      }

      // only return transferState on the browser
      console.log('Browser EnsemblePlpResolver');

      // if the page was pre-rendered and has query params, we can't use TransferState because it is not filtered
      if (isPreRendered && isParameterizedRoute) {
        console.log(
          'Parameterize route that was pre-rendered.  Fetching Ensembles from Elastic API',
        );
        return firstValueFrom(search$);
      }

      if (!!searchResultsTransferState) {
        console.log('Ensembles TransferState Found.  Using TransferState');
        return searchResultsTransferState;
      }
      console.log(
        'No Ensembles TransferState Found.  Fetching Ensembles from Elastic API',
      );
      return firstValueFrom(search$);
    };

    const searchResults = await getSearchResults();
    // only set TransferState if we actually have some styles on the server
    if (this.isServer && searchResults.ensembles.length > 0) {
      console.log(
        'Search Results ',
        'totalHits: ',
        searchResults.totalHits,
        ' numStyles: ',
        searchResults.ensembles.length,
      );

      this.transferState.set(
        SEARCH_RESULTS_FOR_ENSEMBLES_PLP_KEY,
        searchResults,
      );
    }

    // Check if it is a parameterized route (we do not index parameterized routes)

    if (isParameterizedRoute) {
      this.seoService.setNoIndexTag(true);
      /**
       * If noIndex is ever true, canonical should be removed
       * Default remove canonical.
       *  */
      this.seoService.setCanonicalTag(state.url, false);
    } else {
      this.seoService.setNoIndexTag(metaData?.noIndex ?? undefined);
      this.seoService.setCanonicalTag(state.url, !metaData.noIndex);
    }
    this.seoService.setMetaTags(metaData);
    // route.data = { seoParams: metaData };

    const resolverData: EnsemblePlpResolverData = {
      searchResults,
      filterCategoriesData,
    };
    return resolverData;
  }
}
