import { makeStateKey } from '@angular/core';
import { CategoryItem, EcomEnsemblePLP } from 'common-types';

export type SearchResultsForEnsemblesPlp = {
  ensembles: EcomEnsemblePLP[];
  totalHits: number;
  categoryItem: CategoryItem;
};

export const SEARCH_RESULTS_FOR_ENSEMBLES_PLP_KEY =
  makeStateKey<SearchResultsForEnsemblesPlp>(
    'SEARCH_RESULTS_FOR_ENSEMBLES_PLP_KEY',
  );
