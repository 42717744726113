import { makeStateKey } from '@angular/core';
import { CategoryItem, FilterCategory } from 'common-types';

export type FilterCategoriesSearchResults = {
  filterCategories: FilterCategory[];
  categoryItem: CategoryItem;
};

export const FILTER_CATEGORIES_SEARCH_RESULTS =
  makeStateKey<FilterCategoriesSearchResults>(
    'FILTER_CATEGORIES_SEARCH_RESULTS',
  );
